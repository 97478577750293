import React from 'react'
import { Head, Link } from '~/components'
import { PageProps } from 'gatsby'
import * as st from '~/assets/styl/PageNotFoundAndSucess.module.styl'
import logo from '~/assets/svg/logo-black.svg'

const NotFound = ({ location }: PageProps) => {
  return (
    <>
      <Head location={location} />
      <main className={st.core}>
        <div>
          <img
            src={logo}
            alt="Logo do empreendimento Marina Sunshine - Open city Campeche"
          />
          <div className={st.flex}>
            <section>
              <h1 className="title large-size color-black">
                Página não <span className="block">encontrada.</span>
              </h1>
              <p className="color-gray">
                <strong>Agradecemos por entrar em contato conosco.</strong>
              </p>
              <p className="color-gray">
                Este link não existe ou está fora do ar temporariamente.
              </p>
            </section>
            <Link href="/" className="btn btn-white custom-padding">
              Ir para home
            </Link>
          </div>
        </div>
      </main>
    </>
  )
}

export default NotFound
